<template>
    <div class="row child-component">
        <div class="col-lg-7 col-md-8 col-sm-8 col-xs-6 mx-auto">
            <div class="d-block row col-lg-8 mx-auto">
                <div class="fieldborder">
                    <label>Employee Phone Number </label>
                    <the-mask v-if="this.userPhone" type="text" @focus="ResetDirty('EmployeePhoneNumber')"
                        @blur="SetDirty('EmployeePhoneNumber')" :tokens="{ '#': { pattern: /\d/ } }"
                        :mask="['##', '1-###-###-####']" style="margin-top:1px;" class="form-control" autocomplete="off"
                        :maxlength="14" v-model="MediaVendor.EmployeePhoneNumber" placeholder="Employee Phone Number"
                        disabled></the-mask>

                    <b-form-input v-else placeholder="N/A" disabled></b-form-input>

                    <div class="error-message-format"
                        v-if="$v.MediaVendor.EmployeePhoneNumber.$dirty && !$v.MediaVendor.EmployeePhoneNumber.required">
                        Required field
                    </div>
                </div>
                <div class="fieldborder mt-2">
                    <label>ENTITY </label>
                    <OmniSelect class="" placeholder="Select Entity" name="Entity"
                    :showLabel="false" label="value" v-model="MediaVendor.Entity" :options="EntityList"></OmniSelect>
                </div>
                <div class="fieldborder mt-2">
                    <label>AGENCY </label>
                    <OmniSelect placeholder="Select Agency" name="Media" :showLabel="false" label="Select Media"
                        :options="AgencyList" v-model="MediaVendor.EmployeeAgency" />
                </div>
                <div class="fieldborder pt-2">
                    <OmniSelect v-model="MediaVendor.ClientCode" name="Please select the Client Code that is related to this Media Vendor Request" placeholder="Select Client Code" :allowFiltering="true" :showSearch="true" multiple="true" :options="ClientCodeList" />
                </div>

            </div>
            <!-- <div class="d-block row col-lg-8 offset-lg-2 mt-3 agree-checkbox">
            <b-form-checkbox value="true" v-model="MediaVendor.Attestation">By checking this box, the requestor is confirming that he/she/they has conducted all acceptable measures of due diligence to ensure the propriety of the vendor and confirm that the information contained on the form is accuraate to the best of their knowledge</b-form-checkbox>
        </div> -->
        </div>
        <div class="form-group pt-60 text-right  footer-buttons" style="padding-right:96px !important;">
            <button class="btn btn-link text-uppercase" @click="Cancel()">
                CANCEL
            </button>
            <button class="text-uppercase btn custom-button btn-primary" @click="Previous()">
                BACK
            </button>
            <button class="text-uppercase btn custom-button btn-primary" @click="Next()" :disabled="this.$v.$invalid">
                NEXT
            </button>
        </div>

    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { TheMask } from 'vue-the-mask'
import service from "../../../services/Dropdowns";
import { required, minLength, requiredIf, maxLength, numeric } from "vuelidate/lib/validators";
export default {
    data() {
        return {
            AgencyList:[],
            EntityList:[],
            userPhone: null,
            ClientCodeList: [],
        }
    },
    components: {
        TheMask
    },
    props: {
        id: {
            type: Number,
            required: false,
        },
        isDraft: {
            type: Boolean,
            required: false
        }
    },
    validations: {
        MediaVendor: {
            EmployeePhoneNumber: {
                required: requiredIf(function () {
                    return this.userPhone;
                }),
                minLength: minLength(10),
                maxLength: maxLength(10),
                numeric
            },
            Entity: {
                required
            },
            EmployeeAgency: {
                required
            },
        }
    },
    computed: {
        IsEdit() {
            return this.id && !isNaN(this.id) && parseInt(this.id) > 0 ? true : false;
        },
        ...mapGetters('mediavendor', {
            MediaVendor: 'MediaVendor',
        }),
    },
    mounted() {
        this.getPhoneNumber();
        this.LoadClientCodes();
        this.LoadDropdownValues();
    },
    activated() {
        this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
    },
    methods: {
        async LoadDropdownValues() {
            service.getOriginalEntityRecords("Financial Entity")
                .then((res) => {
                this.EntityList = res.data;
                    if (this.IsEdit) {
                        this.LoadEntitySelectedValue();
                    }
                })
                .catch((err) => {
                    alert(err);
                });
        },
        EntityChange(isManualChanges = false) {
            var entityid = this.MediaVendor.Entity;
            if(isManualChanges) {
                this.MediaVendor.EmployeeAgency = null;
            }
            service
                .getAgencyDropdownValues(entityid, entityid)
                .then((res) => {
                    this.AgencyList = res.data;
                    if (this.AgencyList && this.AgencyList.length === 1) {
                        this.MediaVendor.EmployeeAgency = this.AgencyList[0].value;
                    } else if (isManualChanges) {
                        this.MediaVendor.EmployeeAgency = null;
                    }
                    if (this.IsEdit) {
                        this.AssignAgencyName();
                    }
                })
                .catch((err) => {
                    alert(err);
                });
        },
        LoadEntitySelectedValue() {
            if (this.IsEdit) {
                    this.$set(this.MediaVendor, "Entity", this.MediaVendor.Entity);
                if (this.MediaVendor.Entity == this.MediaVendor.Entity) {
                    this.EntityChange();
                }
            }
        },
        AssignAgencyName() {
            let agency = this.AgencyList.findIndex((item) => {
                return item.value == this.MediaVendor.EmployeeAgency;
            });
            if (agency > -1) {
                this.MediaVendor.AgencyName = this.AgencyList[agency].label;
            } else {
                this.MediaVendor.AgencyName = "";
            }
        },
        SetDirty(propertyName) {
            this.$v.MediaVendor[propertyName].$touch();

        },
        ResetDirty(propertyName) {
            this.$v.MediaVendor[propertyName].$reset();
        },
        Next() {
            this.$parent.$emit("stepChanged", this.$route.meta.tabIndex);
            if (this.id) {
                this.$router.push({
                    name: "Media Vendor Review",
                    params: {
                        id: this.id,
                        isDraft: this.isDraft
                    }
                });
            } else {
                this.$router.push({
                    name: "Media Vendor Review"
                });
            }
        },
        Cancel() {
            this.$router.push("/VendorRequests");
        },
        Previous() {
            if (this.id) {
                this.$router.push({
                    name: "Media Vendor Relationship Info",
                    params: {
                        id: this.id,
                        isDraft: this.isDraft
                    }
                });
            } else {
                this.$router.push({
                    name: "Media Vendor Relationship Info"
                });
            }
        },
        async getPhoneNumber() {
            try {
                const emailAddress = await this.$store.dispatch("getEmail");
                const res = await this.$store.dispatch("product/GetReachUser", emailAddress);
                if (res.data) {
                    this.MediaVendor.EmployeePhoneNumber = res.data.Phone;
                    this.userPhone = this.MediaVendor.EmployeePhoneNumber.length;
                }
            } catch (ex) {
                if (ex.response) {
                    this.$store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                }
            }
        },
        async LoadClientCodes() {
                this.$store
                    .dispatch("product/GetClients")
                    .then((response) => {
                        this.ClientCodeList = response.data;
                        this.ClientCodeList.forEach(element => { element.value = element.label; });
                    })
                    .catch((ex) => {
                        window.console.log(`Error is ${ex}`);
                    });
        },
    },
    watch: {
        "MediaVendor.Entity": function (val) {
            let entity = this.EntityList.findIndex((item) => {
                return item.value == val;
            });
            if (entity > -1) {
                this.MediaVendor.EntityName = this.EntityList[entity].label;
            } else {
                this.MediaVendor.EntityName = "";
            }
            this.EntityChange(true);
        },
        "MediaVendor.EmployeeAgency": function (val) {
            let agency = this.AgencyList.findIndex((item) => {
                return item.value == val;
            });
            if (agency > -1) {
                this.MediaVendor.AgencyName = this.AgencyList[agency].label;
            } else {
                this.MediaVendor.AgencyName = "";
            }
        },
    },
}
</script>

<style scoped>
.agree-checkbox .custom-control-label {
    font-weight: 400;
    font-size: 12px;
}
</style>
